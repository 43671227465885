import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class ProductCarousel extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
     new Splide(this, {
        perPage    : 4,
        perMove    : 1,
        pagination : false,
        arrows     : false,
        breakpoints: {
          1024: {
            perPage: 1,
            padding: { right: '4rem' }
          },
        }
      }).mount();
    }

}

  customElements.define('product-slider', ProductCarousel);
